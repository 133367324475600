import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// Create a styled button with custom animations
const AniButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  minWidth: 120,
  minHeight: 36,
  padding: '8px 16px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: 0.7,
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&:hover::before': {
    left: 0,
  },
}));

const LoadingButton = ({ children, onClick, loading, ...props }) => {
  return (
    <AniButton
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={onClick}
      {...props}
    >
      {loading ? (
        <CircularProgress
          size={24}
          sx={{
            color: (theme) => theme.palette.common.white,
            position: 'absolute',
          }}
        />
      ) : (
        children
      )}
    </AniButton>
  );
};

export default LoadingButton;
