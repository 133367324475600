import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedNavigationTab: 0,
};

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setNavTab: (state, action) => {
      state.selectedNavigationTab = action.payload;
    },
  },
});

export const { setNavTab } = navigationSlice.actions;

export default navigationSlice.reducer;
