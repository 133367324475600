import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../slices/userSlice';

const useFetchUserData = (url) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      if (userId && token) {
        try {
          const response = await fetch(`${url}/user/${userId}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            window.location.href = "/login";
            return;
          }
          if (!response.ok) {
            throw new Error(`Error fetching user: ${response.statusText}`);
          }
          const userData = await response.json();
          dispatch(setUser(userData));
        } catch (error) {
          console.error(error);
        }
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
      }
      setLoading(false);
    };

    fetchUserData();
  }, [dispatch, url]);

  return loading;
};

export default useFetchUserData;
