import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavTab } from "../slices/navigationSlice";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { setLocation } from "../slices/userSlice";

const LocationList = ({ locations, onSelectLocation }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const user = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const url = process.env.REACT_APP_API_URL;
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    let storedLocation = sessionStorage.getItem("locationNav") || null;
    if (!isNaN(storedLocation)) {
      storedLocation = null;
      sessionStorage.removeItem("locationNav");
    }

    if (storedLocation !== null) {
      setSelectedLocation(storedLocation);
      onSelectLocation(storedLocation);
    } else {
      if (user.locations?.length > 0) {
        setSelectedLocation(user.locations[0].placeId);
        onSelectLocation(user.locations[0].placeId);
      }
    }
  }, [onSelectLocation, sessionStorage.getItem("locationNav")]);

  const handleLocationClick = (placeId) => {
    if (placeId === selectedLocation) setExpanded();
    setSelectedLocation(placeId);
    sessionStorage.setItem("activeTab", 0);
    onSelectLocation(placeId);
    sessionStorage.setItem("locationNav", placeId);
    dispatch(setNavTab(0));
    navigate("/current");
    if (!expanded && placeId !== selectedLocation) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleFavoriteClick = async (event, location) => {
    event.stopPropagation();

    const locationData = {
      placeId: location.placeId,
      primary: !location.primary,
    };

    const response = await fetch(`${url}/user/${userId}/location`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(locationData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const responseData = await response.json();
    dispatch(setLocation(responseData));
    if (!location.primary) {
      sessionStorage.setItem("locationNav", location.placeId);
    }
  };

  if (locations?.length < 1) {
    return (
      <div></div>
    );
  }

  return (
    <Box sx={{ width: "100vw" }}>
      <Grid
        container
        // justifyContent={isMobile ? "left" : "center"}
        // alignItems={isMobile ? "flex-start" : "center"}
        // direction={isMobile ? "column" : "row"}
        sx={{ width: "100%" }}
      >
        {(expanded || !isMobile) &&
          locations.map((location, index) => (
            <Grid
              item
              xs={isMobile ? 12 : 3}
              key={index}
              sx={{
                border: 0.5,
                borderRadius: 5,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: 0.5,
                backgroundColor:
                  selectedLocation === location.placeId
                    ? "rgba(0, 0, 255, 0.1)"
                    : "inherit",
                borderColor:
                  selectedLocation === location.placeId ? "blue" : "inherit",
                cursor: "pointer", // Add cursor pointer here
              }}
              onClick={() => handleLocationClick(location.placeId)}
            >
              <Typography
                variant="caption"
                noWrap
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  marginLeft: 2,
                  fontWeight: "bold",
                }}
              >
                {location.placeText}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={(event) => handleFavoriteClick(event, location)}
                >
                  {location.primary ? (
                    <Star sx={{ color: "#f6c604" }} />
                  ) : (
                    <StarBorder />
                  )}
                </IconButton>
              </Box>
            </Grid>
          ))}
        {!expanded && isMobile === true && (
          <Grid
            item
            xs={12}
            sx={{
              border: 0.5,
              borderRadius: 5,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: 0.5,
              backgroundColor: selectedLocation
                ? "rgba(0, 0, 255, 0.1)"
                : "inherit",
              borderColor: selectedLocation ? "blue" : "inherit",
              cursor: "pointer", // Add cursor pointer here
            }}
            onClick={() => handleToggleExpand()}
          >
            <Typography
              variant="caption"
              noWrap
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginLeft: 2,
                fontWeight: "bold",
              }}
            >
              {selectedLocation !== null
                ? locations.find(
                    (location) => location.placeId === selectedLocation
                  ).placeText
                : locations[0].placeText}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton>
                {selectedLocation !== null ? (
                  locations.find(
                    (location) => location.placeId === selectedLocation
                  )?.primary ? (
                    <Star sx={{ color: "#f6c604" }} />
                  ) : (
                    <StarBorder />
                  )
                ) : locations[0]?.primary ? (
                  <Star sx={{ color: "#f6c604" }} />
                ) : (
                  <StarBorder />
                )}
              </IconButton>

              <IconButton onClick={handleToggleExpand}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </Grid>
        )}
        {expanded && isMobile === true && (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <IconButton onClick={handleToggleExpand}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default LocationList;
