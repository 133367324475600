import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EzBz from "./icons/ezbz";
import { setNavTab } from "./slices/navigationSlice";
import LocationSearch from "./LocationSearch";
import { MenuRounded } from "@mui/icons-material";

function Appbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const activeTab = useSelector(
    (state) => state.navigation.selectedNavigationTab
  );
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    // Load the active tab from sessionStorage if it exists
    const savedTab = sessionStorage.getItem("activeTab");
    if (savedTab !== null) {
      dispatch(setNavTab(Number(savedTab)));
    }
  }, [dispatch]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location.href = "/login";
  };

  const handleChipClick = (index) => {
    dispatch(setNavTab(index));
    sessionStorage.setItem("activeTab", index);

    switch (index) {
      case 0:
        navigate("/current");
        break;
      case 1:
        navigate("/hourly");
        break;
      case 2:
        navigate("/daily");
        break;
      default:
        navigate("/");
        break;
    }
  };
  
  const handleAccountClick = () => {
    handleMenuClose();
    navigate("/account");
  }
  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#4ba9c8", borderRadius: 0 }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            maxWidth: 1000,
            width: "100%",
            margin: "0 auto",
          }}
        >
          <Grid2 container size={12} alignItems="center">
            <Grid2
              item
              size={{ xs: 2, sm: 2 }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  borderTopRightRadius: 25,
                  borderBottomRightRadius: 25,
                }}
              >
                <EzBz
                  style={{
                    width: isSmallScreen === true ? 35 : 45,
                    height: isSmallScreen === true ? 35 : 45,
                  }}
                />
              </Box>
            </Grid2>
            <Grid2
              item
              size={{ xs: 7 }}
              justifyContent="center"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {user?.username && <LocationSearch />}
            </Grid2>
            <Grid2
              item
              size={{ xs: 3 }}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {!user?.username ? (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleLogout}
                >
                  Login
                </Button>
              ) : (
                <>
                  <IconButton onClick={handleMenuOpen}>
                    <MenuRounded
                      color="secondary"
                      sx={{ width: 32, height: 32 }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleAccountClick} sx={{ color: "primary.main" }}>Account</MenuItem>
                    <MenuItem onClick={handleLogout} sx={{ color: "red" }}>
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid2>
          </Grid2>
        </Toolbar>
      </AppBar>
      {isSmallScreen && user?.username && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "background.paper",
            boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            zIndex: theme.zIndex.drawer + 1,
            p: 1.5,
            mt: 2,
          }}
        >
          <Button
            onClick={() => handleChipClick(0)}
            color="secondary"
            size="small"
            variant={activeTab === 0 ? "contained" : "text"}
          >
            Current
          </Button>
          <Divider orientation="vertical" flexItem color="#ffffff" />
          <Button
            onClick={() => handleChipClick(1)}
            color="secondary"
            size="small"
            variant={activeTab === 1 ? "contained" : "text"}
          >
            Hourly
          </Button>
          <Divider orientation="vertical" flexItem color="#ffffff" />
          <Button
            onClick={() => handleChipClick(2)}
            color="secondary"
            size="small"
            variant={activeTab === 2 ? "contained" : "text"}
          >
            Daily
          </Button>
        </Box>
      )}
    </>
  );
}

export default Appbar;
