import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import WaterIcon from "@mui/icons-material/Water";
import AirIcon from "@mui/icons-material/Air";
import CloudIcon from "@mui/icons-material/Cloud"; // Clouds icon
import OpacityIcon from "@mui/icons-material/Opacity"; // Precipitation icon
import AcUnitIcon from "@mui/icons-material/AcUnit"; // Snow icon
import moment from "moment-timezone";
import theme from "../theme";
import SunriseSunsetInfographic from "../utils/SunriseSunsetInfographic"; // Import the new component
import simpleDescription from "../utils/simpleDescription";
import getWeatherIcon from "../utils/getWeatherIcon";
import mmToInches from "../utils/mmToInches";

// Helper to format wind direction based on degrees
function formatDirection(degrees) {
  const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
  const index = Math.floor((degrees + 22.5) / 45) % 8;
  return directions[index];
}

export default function CurrentWeatherCard({
  weather,
  nextDaySunrise,
  sunrise,
  sunset,
  dailyPrecip,
  selectedPlace,
}) {
  // Determine precipitation type and volume
  let precipitationIcon = null;
  let precipitationVolume = "0.00";
  let precipitationType = "";

  if (dailyPrecip.rain) {
    precipitationIcon = <OpacityIcon color="primary" sx={{ marginRight: 1 }} />;
    precipitationVolume = mmToInches(dailyPrecip.rain);
    precipitationType = "Rain";
  } else if (dailyPrecip.snow) {
    precipitationIcon = <AcUnitIcon color="primary" sx={{ marginRight: 1 }} />;
    precipitationVolume = mmToInches(dailyPrecip.snow);
    precipitationType = "Snow";
  } else {
    precipitationIcon = <OpacityIcon color="primary" sx={{ marginRight: 1 }} />;
  }
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card
      raised
      sx={{
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        minHeight: !matchesXs ? 410 : "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#4ba9c8",
          padding: 0.5, // Darker shade of blue with some transparency
        }}
      >
        <Typography
          color="secondary"
          variant={matchesXs ? "paragraph" : "h6"}
          sx={{ marginLeft: 2 }}
        >
          {`${selectedPlace} as of ${moment()
            .tz(weather.timezone)
            .format("h:mm a")}`}
        </Typography>
      </Box>
      <CardContent>
        <Grid container spacing={matchesXs ? 0 : 1} align="center">
          <Grid item xs={6}>
            <Typography variant={matchesXs ? "h4" : "h2"} component="div">
              {Math.round(weather.current.temp)}°
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="center">
            <Grid container item direction={matchesXs ? "row" : "column"}>
              <Grid item xs={matchesXs ? 6 : 12}>
                {getWeatherIcon(
                  weather.current.weather?.description,
                  matchesXs ? 35 : 55,
                  matchesXs ? 35 : 55,
                  moment().utc().isBefore(moment(weather.current.sunrise)) ||
                    moment().utc().isAfter(moment(weather.current.sunset))
                )}
              </Grid>
              <Grid item xs={matchesXs ? 6 : 12}>
                <Typography variant={matchesXs ? "caption" : "subtitle1"}>
                  {simpleDescription(
                    weather.current.weather?.description,
                    moment().utc().isBefore(moment(weather.current.sunrise)) ||
                      moment().utc().isAfter(moment(weather.current.sunset))
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider
              color="#000000"
              variant="middle"
              sx={{ marginBottom: 0.5 }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            spacing={matchesXs ? 0 : 1}
          >
            <Grid item xs={6} container justifyContent="center">
              <Typography
                variant={matchesXs ? "caption" : "body2"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <WaterIcon color="primary" sx={{ marginRight: 1 }} />{" "}
                {`Humidity: ${weather.current.rh}%`}
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="center">
              <Typography
                variant={matchesXs ? "caption" : "body2"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AirIcon color="primary" sx={{ marginRight: 1 }} />{" "}
                {Math.round(weather.current.wind_spd)} mph|{" "}
                {formatDirection(weather.current.wind_dir)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="center">
              <Typography
                variant={matchesXs ? "caption" : "body2"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloudIcon color="primary" sx={{ marginRight: 1 }} />{" "}
                {`Coverage: ${weather.current.clouds}%`}
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="center">
              <Typography
                variant={matchesXs ? "caption" : "body2"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {precipitationIcon} {dailyPrecip.pop.toFixed(0)}%,{" "}
                {precipitationVolume} in
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SunriseSunsetInfographic
                sunrise={sunrise}
                sunset={sunset}
                nextSunrise={nextDaySunrise}
                timezone={weather.timezone}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
