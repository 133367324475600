import { ExpandMore, WaterDrop } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment-timezone";
import getWeatherIcon from "../utils/getWeatherIcon";
import simpleDescription from "../utils/simpleDescription";
import HalfDayOverview from "./HalfDayOverview";
import theme from "../theme";
import DailyDetails from "./DailyDetails";
import { useEffect } from "react";

const DailyWeather = ({ daily, timezone, loading }) => {
  const mdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const handleScroll = () => {
      window.scrollTo(0, 0);
    };
    handleScroll();
  },[]);
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }


  const currentHour = moment().hour();
  const isEvening = currentHour >= 17;

  const convertToTimezone = (timestamp, timezone) => {
    return moment.tz(timestamp * 1000, timezone).format("hh:mm A");
  };

  return (
    <Box sx={{ maxWidth: 1000 }}>
      <Grid container spacing={0}>
        {daily.data.map((day, index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                backgroundColor: "#4ba9c8",
                borderTopLeftRadius: index === 0 ? 10 : 0,
                borderTopRightRadius: index === 0 ? 10 : 0,
              }}
            >
              <Typography
                variant={mdOrUp === true ? "h5" : "h6"}
                color="secondary"
                sx={{ marginLeft: 2 }}
              >
                {moment(day.ts * 1000).format("ddd, MMM Do")}
              </Typography>
            </Box>
            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={mdOrUp ? 1 : 3}>
                    {getWeatherIcon(
                      day.weather.description,
                      mdOrUp ? 55 : 40,
                      mdOrUp ? 55 : 40
                    )}
                  </Grid>
                  <Grid item xs={mdOrUp ? 2 : 4}>
                    <Typography variant={mdOrUp === true ? "body1" : "caption"}>
                      {simpleDescription(day.weather.description)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={mdOrUp ? 4 : 5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography
                        variant={mdOrUp === true ? "h4" : "h6"}
                        sx={{ color: "#000" }}
                      >
                        {Math.round(day.max_temp)}°/
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={mdOrUp === true ? "h5" : "body1"}
                        sx={{ color: "#777" }}
                      >
                        {Math.round(day.min_temp)}°
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <WaterDrop
                          color="primary"
                          style={{
                            marginRight: "4px",
                            height: mdOrUp === true ? 25 : 15,
                            width: mdOrUp === true ? 25 : 15,
                          }}
                        />
                        {Math.round(day.pop)}%
                      </Typography>
                    </Grid>
                  </Grid>
                  {mdOrUp === true && (
                    <Grid item xs={2} sx={{ height: "100%" }}>
                      <HalfDayOverview
                        text={"Feels Like"}
                        min={day.app_min_temp}
                        max={day.app_max_temp}
                      />
                    </Grid>
                  )}
                  {mdOrUp === true && (
                    <Grid item xs={2} sx={{ height: "100%" }}>
                      <HalfDayOverview
                        text={"Sunrise/Sunset"}
                        min={convertToTimezone(day.sunset_ts, timezone)}
                        max={convertToTimezone(day.sunrise_ts, timezone)}
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {mdOrUp === false && (
                    <Grid item xs={6} sx={{ height: "100%" }}>
                      <HalfDayOverview
                        text={"Feels Like"}
                        min={day.app_min_temp}
                        max={day.app_max_temp}
                      />
                    </Grid>
                  )}
                  {mdOrUp === false && (
                    <Grid
                      item
                      xs={
                        !isEvening ||
                        !moment(day.dt * 1000).isSame(moment(), "day")
                          ? 6
                          : 12
                      }
                      sx={{ height: "100%" }}
                    >
                      <HalfDayOverview
                        text={"Sunrise/Sunset"}
                        min={convertToTimezone(day.sunset_ts, timezone)}
                        max={convertToTimezone(day.sunrise_ts, timezone)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <DailyDetails day={day} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DailyWeather;
