// ThreeHourForecast.jsx

import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Collapse,
  Divider,
  useMediaQuery,
  Box,
} from '@mui/material';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import moment from 'moment-timezone';
import simpleDescription from '../utils/simpleDescription';
import getWeatherIcon from '../utils/getWeatherIcon';
import theme from '../theme';

const WeatherDataRow = ({ data, sunriseSunset }) => {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { timestamp_local, timestamp_utc, temp, weather, pop } = data;
  const startTime = moment(timestamp_utc);
  const localStartTime = moment(timestamp_local);

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="subtitle1" fontWeight="bold">
            {localStartTime.format('h a')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{Math.round(temp)}°</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            {getWeatherIcon(
              weather.description,
              24,
              24,
              moment(startTime).isBefore(sunriseSunset.sunrise) ||
                moment(startTime).isAfter(sunriseSunset.sunset)
            )}
            <Typography variant="body2" ml={1}>
              {simpleDescription(
                weather.description,
                moment(startTime).isBefore(sunriseSunset.sunrise) ||
                  moment(startTime).isAfter(sunriseSunset.sunset)
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            <WaterDropIcon color="primary" fontSize="small" /> {pop}%
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
    </>
  );
};

export default function ThreeHourForecast({ weatherData, daily }) {
  moment.tz.setDefault('America/New_York');
  const [expanded, setExpanded] = useState(false);
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  // Filter the weather data to get every third hour
  const filteredWeatherData = weatherData.filter((_, index) => index % 3 === 0);

  // Convert daily data into a lookup table for sunrise and sunset times
  const sunriseSunsetLookup = daily.data.reduce((acc, day) => {
    const date = moment(day.datetime).format('MM-DD-YYYY');
    acc[date] = {
      sunrise: moment(day.sunrise),
      sunset: moment(day.sunset),
    };
    return acc;
  }, {});

  return (
    <Card sx={{ borderRadius: 4, boxShadow: 3, height: '100%' }}>
      <Box sx={{ backgroundColor: '#4ba9c8', p: 1 }}>
        <Typography variant="h6" color="white">
          Looking Ahead
        </Typography>
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        {filteredWeatherData.slice(0, matchesSm ? 3 : 4).map((item, index) => (
          <WeatherDataRow
            key={index}
            data={item}
            sunriseSunset={
              sunriseSunsetLookup[moment(item.timestamp_local).format('MM-DD-YYYY')]
            }
          />
        ))}
        <Collapse in={expanded}>
          {filteredWeatherData.slice(matchesSm ? 3 : 4, matchesSm ? 6 : 8).map(
            (item, index) => (
              <WeatherDataRow
                key={index}
                data={item}
                sunriseSunset={
                  sunriseSunsetLookup[moment(item.timestamp_local).format('MM-DD-YYYY')]
                }
              />
            )
          )}
        </Collapse>
        <Button onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
      </CardContent>
    </Card>
  );
}
