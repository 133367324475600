import { Button, Grid, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme from "../theme";
import { setNavTab } from "../slices/navigationSlice";
import { useNavigate } from "react-router-dom";

export default function PrimaryNav() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const activeTab = useSelector(
    (state) => state.navigation.selectedNavigationTab
  );

  const handleChipClick = (index) => {
    dispatch(setNavTab(index));
    sessionStorage.setItem("activeTab", index);

    switch (index) {
      case 0:
        navigate("/current");
        break;
      case 1:
        navigate("/hourly");
        break;
      case 2:
        navigate("/daily");
        break;
      default:
        navigate("/");
        break;
    }
  };

  return (
    <div style={{marginTop: 10}}>
      {!isSmallScreen && user?.username && (
        <Grid container justifyContent="center" spacing={2} sx={{marginBottom: 2}}>
          <Grid item>
            <Button
              onClick={() => handleChipClick(0)}
              color="primary"
              variant={activeTab === 0 ? "contained" : "outlined"}
            >
              Current
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleChipClick(1)}
              color="primary"
              variant={activeTab === 1 ? "contained" : "outlined"}
            >
              Hourly
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleChipClick(2)}
              color="primary"
              variant={activeTab === 2 ? "contained" : "outlined"}
            >
              Daily
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
