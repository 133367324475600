import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';

const VerifyCodeDialog = ({ open, onClose, newEmail,setNewEmail, setUpdatedEmail }) => {
  const url = process.env.REACT_APP_API_URL;
  const user = useSelector((state) => state.user);
  const userLoading = useSelector((state) => state.user.userLoading); // Access loading state
  const [resetCode, setResetCode] = useState(Array(6).fill(''));
  const [resetCodeError, setResetCodeError] = useState('');
  const [toastOpen, setToastOpen] = useState(false);

  const handleResetCodeVerification = async () => {
    setResetCodeError('');
    const verificationCode = resetCode.join('');

    if (verificationCode.length !== 6) {
      setResetCodeError('Please enter a valid 6-digit code.');
      return;
    }

    try {
      const response = await fetch(`${url}/user/${user._id}/verifyEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ verificationCode }),
      });

      const data = await response.json();

      if (!response.ok) {
        setResetCodeError(data.message || 'Verification failed, please try again.');
        return;
      }

      setToastOpen(true);
      setUpdatedEmail(newEmail); // Set local state for updated email
      setNewEmail('');
      handleCodeDialogClose();
    } catch (error) {
      setResetCodeError('Error verifying code: ' + error.message);
    }
  };

  const handleCodeDialogClose = () => {
    onClose(false);
    setResetCode(Array(6).fill(''));
    setResetCodeError('');
  };

  // Input handling for reset code
  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value)) {
      const newCode = [...resetCode];
      newCode[index] = value;
      setResetCode(newCode);

      if (index < 5) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData('Text').trim();

    if (pastedValue.length === 6 && /^\d{6}$/.test(pastedValue)) {
      setResetCode(pastedValue.split(''));
    } else {
      const newCode = [...resetCode];
      const chars = pastedValue.split('');
      for (let i = 0; i < chars.length && index + i < 6; i++) {
        if (/^[0-9]$/.test(chars[i])) {
          newCode[index + i] = chars[i];
        }
      }
      setResetCode(newCode);
    }
  };

  const handleKeyDown = (e, index) => {
    const key = e.key;

    if (key === 'Backspace') {
      e.preventDefault();
      const newCode = [...resetCode];
      if (resetCode[index]) {
        newCode[index] = '';
        setResetCode(newCode);
      } else if (index > 0) {
        document.getElementById(`code-input-${index - 1}`).focus();
        newCode[index - 1] = '';
        setResetCode(newCode);
      }
    } else if (key === 'ArrowLeft' && index > 0) {
      document.getElementById(`code-input-${index - 1}`).focus();
    } else if (key === 'ArrowRight' && index < 5) {
      document.getElementById(`code-input-${index + 1}`).focus();
    } else if (key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (resetCode.every((digit) => /^[0-9]$/.test(digit))) {
      handleResetCodeVerification();
    } else {
      setResetCodeError('Please enter a valid 6-digit code.');
    }
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      sx={{ marginBottom: 30 }}
    >
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Typography color="primary" variant="body2" sx={{ marginBottom: 3 }}>
          Please enter the 6-digit code that was sent to your new email address to confirm the change.
        </Typography>
        <Grid2 container spacing={1} justifyContent="center" wrap="wrap">
          {resetCode.map((digit, index) => (
            <Grid2 item xs={4} sm={2} key={index}>
              <TextField
                id={`code-input-${index}`}
                value={digit}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={(e) => handlePaste(e, index)}
                disabled={userLoading} // Disable during loading
                inputProps={{
                  style: {
                    textAlign: 'center',
                    height: '100%',
                    fontSize: '1.5rem',
                  },
                  inputMode: 'numeric',
                }}
                sx={{
                  width: '3.5rem',
                  height: '3.5rem',
                  textAlign: 'center',
                }}
              />
            </Grid2>
          ))}
        </Grid2>
        {resetCodeError && (
          <Typography variant="body2" color="error" align="center">
            {resetCodeError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={userLoading}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={userLoading}>
          {userLoading ? <CircularProgress size={24} /> : 'Verify'}
        </Button>
      </DialogActions>

      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
      >
        <Alert onClose={() => setToastOpen(false)} severity="success" sx={{ width: '100%' }}>
          Your email has been updated successfully.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default VerifyCodeDialog;
