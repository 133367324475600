import { useState, useEffect } from "react";

const useWeatherData = (user, selectedPlaceId, shouldRefetch) => {
  const [loading, setLoading] = useState(true);
  const [currentWeather, setCurrentWeather] = useState([]);
  const [hourlyData, setHourlyData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchWeatherData = async (placeId) => {
      try {
        setLoading(true);
        const response = await fetch(`${url}/weather?placeId=${placeId}`);
        const data = await response.json();

        const hourlyDataResponse = await fetch(
          `${url}/weather/hourly?lat=${data.lat}&lon=${data.lon}`
        );
        const hourlyData = await hourlyDataResponse.json();

        const dailyResponse = await fetch(
          `${url}/weather/daily?lat=${data.lat}&lon=${data.lon}`
        );
        const dailyData = await dailyResponse.json();

        const alertResponse = await fetch(
          `${url}/weather/alerts?lat=${data.lat}&lon=${data.lon}`
        );

        const alertData = await alertResponse.json();

        return { data, hourlyData, dailyData, alertData };
        // return { data, threeHourData, dailyData };
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const fetchAllWeatherData = async () => {
      if (
        user &&
        user.locations.length > 0 &&
        selectedPlaceId &&
        user.locations.find(
          (location) => location.placeId === selectedPlaceId.toString()
        )
      ) {
        const allWeatherData = await Promise.all(
          [
            user.locations.find(
              (location) => location.placeId === selectedPlaceId.toString()
            ),
          ].map(async (location) => {
            const result = await fetchWeatherData(location.placeId);
            return result;
          })
        );

        const filteredResults = allWeatherData.filter(
          (result) => result !== null
        );

        setCurrentWeather(filteredResults.map((result) => result.data));
        setHourlyData(filteredResults.map((result) => result.hourlyData));
        setDailyData(filteredResults.map((result) => result.dailyData));
        setAlertData(filteredResults.map((result) => result.alertData));
        setLoading(false);
      }
    };

    fetchAllWeatherData();
  }, [user, user.locations, selectedPlaceId, shouldRefetch]);

  return { loading, currentWeather, hourlyData, dailyData, alertData };
};

export default useWeatherData;
