import React from "react";
import { TextField, Autocomplete, InputAdornment } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLocation } from "./slices/userSlice";
import { setNavTab } from "./slices/navigationSlice";
import { useNavigate } from "react-router-dom";
import SearchIcon from "./icons/search";

function LocationSearch() {
  const url = process.env.REACT_APP_API_URL;
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const userId = localStorage.getItem("userId");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log(inputValue);
    if (inputValue.length < 3) return;

    console.log(inputValue.length);
    fetch(
      `${url}/geocode?address=${encodeURIComponent(inputValue)}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.predictions) {
          setOptions(
            data.predictions.map((place) => ({
              label: place.description,
              placeId: place.place_id,
              mainText: place.structured_formatting.main_text,
              secondaryText: place.structured_formatting.secondary_text,
            }))
          );
        }
      })
      .catch(console.error);
  }, [inputValue]);

  
  const onPlaceSelected = async (value) => {
    if (!value) return;

    const locationData = {
      placeId: value.placeId,
      label: value.label,
      mainText: value.mainText,
      secondaryText: value.secondaryText,
    };

    try {
      const response = await fetch(`${url}/user/${userId}/location`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(locationData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      dispatch(setLocation(responseData));
      dispatch(setNavTab(0));
      sessionStorage.setItem("activeTab", 0);
      sessionStorage.setItem("locationNav", responseData[0].placeId);
      navigate("/current");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <Autocomplete
      freeSolo
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      style={{ width: '100%', marginLeft: 10 }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              backgroundColor: '#fff', // Ensure the background is white
              '& fieldset': {
                border: '1px solid #dadce0', // Light grey border
              },
              '&:hover fieldset': {
                border: '1px solid #c0c0c0', // Darker border on hover
              },
              '&.Mui-focused fieldset': {
                border: '2px solid #1a73e8', // Blue border when focused
              },
              '& .MuiInputBase-input': {
                color: 'black' // Set text color to black for visibility
              }
            }
          }}
          label="" // Removed label to avoid visibility issues
          variant="outlined"
          size="small"
          color="primary"
          placeholder="Search" // Use placeholder to indicate input purpose
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{height:'30px', width:'30px'}} />
              </InputAdornment>
            ),
          }}
        />
      )}
      onChange={(event, value) => onPlaceSelected(value)}
      PaperComponent={({ children }) => (
        <div style={{
          boxShadow: '0 4px 6px rgba(0,0,0,0.12)', // Shadow for dropdown
          marginTop: '2px',
          backgroundColor: '#fff', // Ensure dropdown background is white
          color: '#000', // Ensure text color is black for visibility
          visibility: 'visible', // Ensure options are visible
          borderRadius: 10
        }}>
          {children}
        </div>
      )}
    />
  );
}

export default LocationSearch;
