import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  CssBaseline,
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
  Paper,
  Toolbar,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Login from "./Login";
import Register from "./Register";
import Appbar from "./Appbar";
import CurrentWeather from "./CurrentWeather";
import HourlyWeather from "./HourlyWeather";
import DailyWeather from "./DailyWeather";
import { useSelector } from "react-redux";
import PrivateRoute from "./routing/PrivateRoute.js";
import useWeatherData from "./hooks/useFetchWeatherData.js";
import useFetchUserData from "./hooks/useFetchUserData.js";
import LocationList from "./LocationList/LocationList.js";
import PrimaryNav from "./NavButtons/PrimaryNav.js";
import "typeface-inter";
import ForgotPassword from "./ForgotPassword.js";
import ResetPassword from "./ResetPassword.js";
import UserAccount from "./User/UserAccount.js";

function App() {
  const user = useSelector((state) => state.user);
  const [selectedLocation, setSelectedLocation] = useState(
    sessionStorage.getItem("locationNav") || user.locations[0]?.placeId
  );
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Default to 0

  const smOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const phone = useMediaQuery(theme.breakpoints.only("xs"));

  const url = process.env.REACT_APP_API_URL;

  const userLoading = useFetchUserData(url);
  const { loading, currentWeather, hourlyData, dailyData, alertData } = useWeatherData(
    user,
    selectedLocation,
    shouldRefetch,
  );

  useEffect(() => {
    setSelectedLocation(sessionStorage.getItem("locationNav") || user.locations[0]?.placeId);
  }, [user?.locations]);

  useEffect(() => {
    const storedActiveTab = sessionStorage.getItem("activeTab");
    if (storedActiveTab !== null) {
      setActiveTab(Number(storedActiveTab));
    }
  }, []);

    // Event listener to detect app visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setShouldRefetch(true); // Trigger refetch
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  

  if (userLoading) {
    return <CircularProgress />;
  }

  const selectedWeatherData = currentWeather[0] || {};
  const selectedHourlyData = hourlyData[0] || {};
  const selectedDailyData = dailyData[0] || {};
  const selectedAlertData = alertData[0] || {};

  const isUserLoggedIn = !!user?.username || !!localStorage.getItem("token");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Appbar />
        {!userLoading && isUserLoggedIn && selectedLocation && (
          <>
            <Paper
              elevation={0}
              sx={{
                width: "100vw",
                backgroundColor: "#7edcfb",
                borderRadius: 0,
              }}
            >
              <Toolbar
                sx={{
                  justifyContent: "center",
                  maxWidth: 1000,
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <LocationList
                  locations={user.locations}
                  onSelectLocation={setSelectedLocation}
                />
              </Toolbar>
            </Paper>
            <PrimaryNav />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: smOrUp === true ? "2vw" : 1,
            marginRight: smOrUp === true ? "2vw" : 1,
            marginTop: 1,
            marginBottom: phone === true && isUserLoggedIn ? 8 : 3,
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                isUserLoggedIn ? (
                  <Navigate
                    to={
                      activeTab === 0
                        ? "/current"
                        : activeTab === 1
                        ? "/hourly"
                        : "/daily"
                    }
                  />
                ) : (
                  <Typography>Please login</Typography>
                )
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/forgotPassword/:token" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/" />} />

            <Route element={<PrivateRoute />}>
              <>
                <Route
                  path="/current"
                  element={
                    <CurrentWeather
                      weather={selectedWeatherData}
                      hourlyData={selectedHourlyData}
                      dailyData={selectedDailyData}
                      alertData={selectedAlertData}
                      selectedPlace={
                        user.locations.find(
                          (location) => location.placeId === selectedLocation
                        )?.placeText
                      }
                      loading={loading}
                    />
                  }
                />
                <Route
                  path="/hourly"
                  element={
                    <HourlyWeather
                      hourly={selectedHourlyData}
                      daily={selectedDailyData}
                      loading={loading}
                    />
                  }
                />
                <Route
                  path="/daily"
                  element={
                    <DailyWeather
                      daily={selectedDailyData}
                      timezone={selectedWeatherData.timezone}
                      loading={loading}
                    />
                  }
                />
                <Route path="/account" element={<UserAccount />} />
              </>
            </Route>
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
