import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import formatDirection from "../utils/formatDirection";

export default function DailyDetails({ day }) {
  return (
    <Box
      sx={{
        border: 1,
        borderColor: "#4ba9c8",
        borderRadius: 2,
        padding: 0.5,
      }}
    >
      <Grid
        container
        direction="row"
        alignContent="center"
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={6}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Typography variant="body2">Humidity: {day.rh}%</Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Divider
              variant="middle"
              color="#4ba9c8"
              sx={{ margintop: 1, marginLeft: 1, marginBottom: 1 }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {`Wind: ${Math.round(day.wind_spd)} mph, ${formatDirection(day.wind_dir)}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Typography variant="body2">Cloudiness: {day.clouds}%</Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Divider
              variant="middle"
              color="#4ba9c8"
              sx={{ margintop: 1, marginLeft: 1, marginBottom: 1 }}
            />
          </Grid>
          {(day.precip || day.snow) && (
            <>
              <Grid item>
                <Typography variant="body2">
                  {day.precip ? `Rain: ${day.precip.toFixed(2)} in` : `Snow: ${day.snow} in`}
                </Typography>
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Divider
                  variant="middle"
                  color="#4ba9c8"
                  sx={{ margintop: 1, marginLeft: 1, marginBottom: 1 }}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="body2">Gust: {day.wind_gust_spd} mph</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
