import React from "react";
import {
  Box,
  Typography,
  LinearProgress,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import SunnyIcon from "../icons/sunny";
import { Brightness3 } from "@mui/icons-material";
import moment from "moment-timezone";

const getProgress = (currentTime, start, end) => {
  const startTime = moment(start).valueOf();
  const endTime = moment(end).valueOf();
  const current = moment(currentTime).valueOf();
  return ((current - startTime) / (endTime - startTime)) * 100;
};

const getTimeLeft = (currentTime, endTime) => {
  const duration = moment.duration(moment(endTime).diff(moment(currentTime)));
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${hours}h ${minutes}m`;
};

const convertToTimezone = (timestamp, timezone) => {
  return moment.tz(timestamp * 1000, timezone);
};

const SunriseSunsetInfographic = ({
  sunrise,
  sunset,
  nextSunrise,
  timezone
}) => {
  const theme = useTheme();
  const current = convertToTimezone(moment().utc().unix(), timezone);
  const sunriseTime = convertToTimezone(sunrise, timezone);
  const sunsetTime = convertToTimezone(sunset, timezone);
  const nextSunriseTime = convertToTimezone(nextSunrise, timezone);
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  let isDaytime;
  let progress;
  let displayTime;
  let timeLeft;
  let leftTime, rightTime;


  if (current.isBetween(sunriseTime, sunsetTime)) {
    isDaytime = true;
    progress = getProgress(current, sunriseTime, sunsetTime);
    displayTime = `Sunset: ${sunsetTime.format("h:mm a")}`;
    timeLeft = getTimeLeft(current, sunsetTime);
    leftTime = sunriseTime.format("h:mm a");
    rightTime = sunsetTime.format("h:mm a");
  } else {
    isDaytime = false;
    if (current.isAfter(sunsetTime)) {
      progress = getProgress(current, sunsetTime, nextSunriseTime);
      displayTime = `Sunrise: ${nextSunriseTime.format("h:mm a")}`;
      timeLeft = getTimeLeft(current, nextSunriseTime);
      leftTime = sunsetTime.format("h:mm a");
      rightTime = nextSunriseTime.format("h:mm a");
    } else {
      const previousSunset = sunsetTime.clone().subtract(1, "day");
      progress = getProgress(current, previousSunset, sunriseTime);
      displayTime = `Sunrise: ${sunriseTime.format("h:mm a")}`;
      timeLeft = getTimeLeft(current, sunriseTime);
      leftTime = previousSunset.format("h:mm a");
      rightTime = sunriseTime.format("h:mm a");
    }
  }


  return (
    <>
    <Divider color="#000000" variant="middle" sx={{ margin: 1, marginLeft: 2, marginRight: 2 }} />
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <SunnyIcon
            style={{ height: matchesXs ? "30px" : "40px", width: matchesXs ? "30px" : "40px" }}
            color={theme.palette.warning.main}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Brightness3
            style={{
              height: matchesXs ? "20px" : "28px",
              width: matchesXs ? "20px" : "28px",
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Box>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: matchesXs ? "20px" : "40px" }}
      >
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            width: "100%",
            height: matchesXs ? 13 : 20,
            borderRadius: 5,
            my: 2,
            backgroundColor: "#c0c0c0",
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
              backgroundColor: isDaytime
                ? theme.palette.warning.main
                : theme.palette.primary.main,
            },
          }}
        />
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Typography
            color="secondary"
            variant={matchesXs ? "caption" : "h6"}
            sx={{ fontWeight: "bold" }}
          >
            {timeLeft}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography color="primary" variant={matchesXs ? "caption" : "body1"}>
          {isDaytime
            ? `Sunrise: ${sunriseTime.format("h:mm a")}`
            : `Sunset: ${sunsetTime.format("h:mm a")}`}
        </Typography>
        <Typography color="primary" variant={matchesXs ? "caption" : "body1"}>
          {displayTime}
        </Typography>
      </Box>
    </Box>
    </>
  );
};

export default SunriseSunsetInfographic;
