import { combineReducers } from 'redux';
import userReducer from './userSlice';
import navigationReducer from './navigationSlice';

const rootReducer = combineReducers({
  user: userReducer,
  navigation: navigationReducer,
});

export default rootReducer;
