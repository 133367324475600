import React from 'react';
import { Box, Typography } from '@mui/material';

const LocationSearchInfo = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Search for your location
      </Typography>
      <Typography variant="body1" color="primary">
        Use the search bar above to find your location and get real-time weather updates.
      </Typography>
    </Box>
  );
};

export default LocationSearchInfo;
