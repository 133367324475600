import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "./utils/LoadingButton";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for the button
  const navigate = useNavigate();
  const { token } = useParams(); // Extract token from URL parameters
  const url = process.env.REACT_APP_API_URL; // Assuming you have a base API URL in your environment variables

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${url}/user/updatePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, password }),
      });

      if (!response.ok) {
        throw new Error("Error resetting password.");
      }

      setSuccessMessage("Password has been reset successfully.");
      setError(null);

      // Redirect to login page after a delay
      setTimeout(() => navigate("/login"), 2000);
    } catch (error) {
      setError(error.message);
      setSuccessMessage(null);
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const isPasswordMatch =
    password && confirmPassword && password === confirmPassword;

  return (
    <Grid
      container
      justifyContent="center"
      style={{ minHeight: "100vh", marginTop: 20 }}
    >
      <Grid item xs={10} sm={2} sx={{ minWidth: 300 }}>
        <Paper elevation={6} sx={{ padding: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
              Reset Password
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            {successMessage && (
              <Typography color="primary">{successMessage}</Typography>
            )}
            <TextField
              label="New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={confirmPassword.length > 0 && !isPasswordMatch}
              helperText={
                confirmPassword.length > 0 && !isPasswordMatch
                  ? "Passwords do not match."
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {isPasswordMatch && (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              loading={loading}
              sx={{ marginTop: 2 }}
              disabled={!isPasswordMatch || loading}
            >
              Reset Password
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
