import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import EditEmailDialog from "./EditEmailDialog";
import VerifyCodeDialog from "./VerifyCodeDialog";

const UserAccount = () => {
  const user = useSelector((state) => state.user);
  const url = process.env.REACT_APP_API_URL;

  const [open, setOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  // Local state to handle email update temporarily
  const [updatedEmail, setUpdatedEmail] = useState(user?.username || "");

  // Handle open/close of dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailError("");
    setPasswordError("");
    setCurrentPassword("");
  };

  const handleCodeDialogOpen = () => {
    setCodeDialogOpen(true);
  };

  // Simple email format validation with regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle form submission for email update with validation
  const handleEmailUpdate = async () => {
    setEmailError("");
    setPasswordError("");

    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (currentPassword.length <= 1) {
      setPasswordError("Password must be longer than 1 character.");
      return;
    }

    try {
      const response = await fetch(`${url}/user/${user._id}/updateEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: newEmail,
          password: currentPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.message.includes("Password")) {
          setPasswordError(data.message);
        } else {
          setEmailError(data.message);
        }
        return;
      }

      handleCodeDialogOpen(); // Open verification dialog
      handleClose(); // Close email dialog
    } catch (error) {
      alert("Error updating email: " + error.message);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />

      {/* Update User Info Section */}
      <Typography variant="h6">Account Information</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={updatedEmail}
          margin="normal"
          aria-readonly
          sx={{ backgroundColor: "white" }}
          color="primary"
        />
        <IconButton onClick={handleClickOpen} sx={{ marginLeft: 1 }}>
          <EditIcon />
        </IconButton>
      </Box>

      <Divider sx={{ marginY: 4 }} />

      {/* Update Password Section */}
      <Typography variant="h6">Change Password</Typography>
      <TextField
        fullWidth
        label="Current Password"
        name="currentPassword"
        type="password"
        margin="normal"
      />
      <TextField
        fullWidth
        label="New Password"
        name="newPassword"
        type="password"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Confirm New Password"
        name="confirmPassword"
        type="password"
        margin="normal"
      />
      <Button variant="contained" color="secondary" sx={{ marginTop: 2 }}>
        Update Password
      </Button>

      <EditEmailDialog
        open={open}
        onClose={handleClose}
        newEmail={newEmail}
        setNewEmail={setNewEmail}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        emailError={emailError}
        passwordError={passwordError}
        handleEmailUpdate={handleEmailUpdate}
      />

      <VerifyCodeDialog
        open={codeDialogOpen}
        onClose={() => setCodeDialogOpen(false)} // Pass a function that closes the dialog
        newEmail={newEmail}
        setNewEmail={setNewEmail}
        setUpdatedEmail={setUpdatedEmail}
      />
    </Box>
  );
};

export default UserAccount;
