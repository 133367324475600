import React, { useEffect } from "react";
import CurrentWeatherCard from "./currentWeatherCard";
import ThreeHourForecast from "./threeHourForecast";
import HourlyWeather from "./hourlyWeather";
import AqiUvInfographic from "./AqiUvInfographic";
import { CircularProgress, Grid, Grid2 } from "@mui/material";
import WeatherMap from "./WeatherMap";
import LocationSearchInfo from "../LocationSearchInfo";
import CurrentAlert from "./CurrentAlert";

export default function CurrentWeather({
  weather,
  hourlyData,
  dailyData,
  alertData,
  selectedPlace,
  loading,
}) {
  if (loading && !selectedPlace) {
    return <LocationSearchInfo />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const { aqi, uv } = weather.current;

  console.log(alertData);
  return (
    <Grid2 container alignContent="center" spacing={2} sx={{ maxWidth: 1000 }}>
      {alertData?.alerts?.length > 0 && (
        <Grid2 item size={{ xs: 12 }}>
          <CurrentAlert alertData={alertData} />
        </Grid2>
      )}
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} sm={5} md={4}>
          <CurrentWeatherCard
            weather={weather}
            nextDaySunrise={dailyData?.data[1]?.sunrise_ts}
            sunrise={dailyData?.data[0]?.sunrise_ts}
            sunset={dailyData?.data[0]?.sunset_ts}
            dailyPrecip={{
              pop: dailyData.data[0].pop,
              rain: dailyData.data[0].precip,
              snow: dailyData.data[0].snow,
            }}
            selectedPlace={selectedPlace}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          <WeatherMap lat={weather.lat} lon={weather.lon} zoom={8} />
        </Grid>
      </Grid>

      <Grid2 item size={{ xs: 12, sm: 5 }}>
        <AqiUvInfographic aqi={aqi} uv={uv} />
      </Grid2>
      <Grid2 item size={{ xs: 12, sm: 7 }}>
        <ThreeHourForecast weatherData={hourlyData.data} daily={dailyData} />
      </Grid2>
      <Grid item xs={12} sx={{ width: "100%" }}>
        <HourlyWeather
          hourlyData={hourlyData.data.slice(0, 6)}
          daily={dailyData}
        />
      </Grid>
    </Grid2>
  );
}
