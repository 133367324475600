import SunnyIcon from "../icons/sunny";
import FewClouds from "../icons/fewClouds";
import ScatteredClouds from "../icons/scatteredClouds";
import OvercastClouds from "../icons/overcastClouds";
import LightRain from "../icons/lightRain";
import ThunderstormLightRain from "../icons/thunderstormLightRain";
import ModerateRain from "../icons/moderateRain";
import ThunderstormRain from "../icons/thunderstormRain";
import ThunderstormHeavyRain from "../icons/thunderstormHeavyRain";
import HeavyRain from "../icons/heavyRain";
import ClearSkyNight from "../icons/clearSkyNight";
import FewCloudsNight from "../icons/fewCloudsNight";
import ScatteredCloudsNight from "../icons/scatteredCloudsNight";
import ShowerRain from "../icons/showerRain";

export default function getWeatherIcon(
  description,
  height,
  width,
  isNight = false
) {
  const style = { height: `${height}px`, width: `${width}px` };

  switch (description?.toLowerCase()) {
    case "clear sky":
    case "few clouds":
      return isNight ? (
        <ClearSkyNight style={style} />
      ) : (
        <SunnyIcon style={style} />
      );

    case "scattered clouds":
      return isNight ? (
        <FewCloudsNight style={style} />
      ) : (
        <FewClouds style={style} />
      );
    case "broken clouds":
      return isNight ? (
        <ScatteredCloudsNight style={style} />
      ) : (
        <ScatteredClouds style={style} />
      );
    case "overcast clouds":
      return <OvercastClouds style={style} />;
    case "light rain":
    case "drizzle":
      return <LightRain style={style} />;

    // Group 2xx : Thunderstorm
    case "thunderstorm with light rain":
    case "thunderstorm with light drizzle":
    case "thunderstorm with drizzle":
      return <ThunderstormLightRain style={style} />;
    case "thunderstorm with rain":
    case "thunderstorm with heavy drizzle":
      return <ThunderstormRain style={style} />;
    case "thunderstorm with heavy rain":
      return <ThunderstormHeavyRain style={style} />;

    //Group 5xx Rain

    case "shower rain":
    case "light shower rain":
      return <ShowerRain style={style} />;
    case "moderate rain":
      return <ModerateRain style={style} />;
    case "very heavy rain":
    case "heavy intensity rain":
      case "heavy rain":
      return <HeavyRain style={style} />;
    default:
      return <SunnyIcon style={style} />;
  }
}
