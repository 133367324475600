import React from "react";
import {
  List,
  ListItem,
  Typography,
  Card,
  ListItemText,
  Grid,
  ListSubheader,
  useMediaQuery,
  Button,
} from "@mui/material";
import moment from "moment-timezone";
import getWeatherIcon from "../utils/getWeatherIcon";
import { WaterDrop } from "@mui/icons-material";
import theme from "../theme";
import simpleDescription from "../utils/simpleDescription";
import { useNavigate } from "react-router-dom";

export default function HourlyWeather({ hourlyData, daily }) {
  const navigate = useNavigate();
  moment.tz.setDefault("America/New_York");

  // Function to group data by day
  const groupByDay = (data) => {
    return data.reduce((acc, current) => {
      const date = moment(current.timestamp_local).format("ddd, MMMM Do");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(current);
      return acc;
    }, {});
  };

  // Convert daily data into a lookup table for sunrise and sunset times
  const sunriseSunsetLookup = daily.data.reduce((acc, day) => {
    const date = moment(day.ts * 1000).format("ddd, MMMM Do");
    acc[date] = {
      sunrise: moment(day.sunrise),
      sunset: moment(day.sunset),
    };
    return acc;
  }, {});

  const clickHourlyDetails = () => {
    navigate("/hourly");
  };

  // Grouping the hourly data by day
  const groupedData = groupByDay(hourlyData);

  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesSmOrLess = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card sx={{ boxShadow: 3 }}>
      <List subheader={<li />} sx={{ width: "100%" }}>
        {Object.entries(groupedData).map(([date, hours], index) => (
          <li key={date} style={{ listStyleType: "none" }}>
            <ul style={{ padding: 0 }}>
              <ListSubheader sx={{ background: "#4ba9c8", padding: 0.5 }}>
                <Typography
                  color="secondary"
                  variant={matchesSmOrLess ? "body1" : "h5"}
                  sx={{ marginLeft: 1 }}
                >
                  {date}
                </Typography>
              </ListSubheader>
              {hours.map((hour, idx) => {
                const sunrise = sunriseSunsetLookup[date]?.sunrise;
                const sunset = sunriseSunsetLookup[date]?.sunset;
                const hourTime = moment(hour.timestamp_utc);
                const isNight =
                  hourTime.isBefore(sunrise) || hourTime.isAfter(sunset);
                return (
                  <ListItem
                    key={idx}
                    divider={idx !== hours.length - 1}
                    sx={{ padding: 1 }}
                  >
                    <ListItemText>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Grid item xs={3}>
                          <Typography variant={matchesXs ? "body1" : "h6"}>
                            {moment(hour.timestamp_local).format("h a")}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant={matchesXs ? "h6" : "h5"}>
                            {Math.round(hour.temp)}°
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "35px",
                            }}
                          >
                            {getWeatherIcon(hour.weather.description, 45, 45, isNight)}
                            {!matchesXs && simpleDescription(hour.weather.description, isNight)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <WaterDrop
                              color="primary"
                              style={{ marginRight: "4px" }}
                            />
                            {hour.pop}%
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </ul>
          </li>
        ))}
      </List>
      <Button sx={{ margin: 1 }} onClick={() => clickHourlyDetails()}>
        Get Details
      </Button>
    </Card>
  );
}
