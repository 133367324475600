import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Slider, IconButton, Modal } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

// Fix the marker icon issue by importing the images and setting the icon manually.
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Set default icon for markers to fix broken image issue
L.Marker.prototype.options.icon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const WeatherMap = ({ lat, lon, zoom }) => {
  const [forecastFrames, setForecastFrames] = useState([]);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMovingTime, setIsMovingTime] = useState(false);

  console.log(lat, lon);
  useEffect(() => {
    // Check if the user is on a mobile device
    const checkIfMobile = () => {
      const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;
      setIsMobile(isTouchDevice);
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  useEffect(() => {
    // Fetch available forecast radar data timestamps (nowcast)
    fetch("https://api.rainviewer.com/public/weather-maps.json")
      .then((response) => response.json())
      .then((data) => {
        if (data.radar && data.radar.nowcast && data.radar.nowcast.length > 0) {
          const frames = data.radar.nowcast
            .map((frame) => ({
              path: frame.path,
              time: new Date(frame.time * 1000).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
            }))
            .sort((a, b) => new Date(a.time) - new Date(b.time));
          setForecastFrames(frames);
          setCurrentTime(frames[0].time);
        }
      })
      .catch((error) => {
        console.error("Error fetching RainViewer data:", error);
      });
  }, []);

  // Handle manual frame change via slider
  const handleFrameChange = (event, newValue) => {
    setIsMovingTime(true);
    setCurrentFrameIndex(newValue);
    setCurrentTime(forecastFrames[newValue].time);
  };

  // Toggle fullscreen state
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  // Component to handle touch events and map interactions
  const TouchHandler = ({ isExpanded, isMobile }) => {
    const map = useMapEvents({
      touchstart: (e) => {
        if (!isExpanded && e.touches && e.touches.length === 2) {
          map.dragging.enable(); // Enable dragging when two fingers are used
        }
      },
      touchend: (e) => {
        if (!isExpanded && (!e.touches || e.touches.length < 2)) {
          map.dragging.disable(); // Disable dragging when less than two fingers are used
        }
      },
    });

    useEffect(() => {
      if (isExpanded || !isMobile) {
        map.dragging.enable();
      } else {
        map.dragging.disable();
      }
    }, [isExpanded, isMobile, map]);

    return null;
  };

  const MapCenterHandler = ({ lat, lon }) => {
    const map = useMap();
    useEffect(() => {
      if (!isMovingTime) {
        map.setView([lat, lon], map.getZoom());
      }
    }, [lat, lon]);
  
    return null;
  };
  

  // Function to render the map, used in both normal and expanded views
  const renderMap = () => (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <MapContainer
        center={[lat, lon]} // directly using lat and lon props
        zoom={zoom}
        style={{ width: "100%", height: "100%" }}
        scrollWheelZoom={true}
        dragging={isExpanded || !isMobile}
        touchZoom={true}
      >
        <TileLayer
          attribution="&copy; OpenStreetMap contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {forecastFrames.length > 0 && (
          <TileLayer
            url={`https://tilecache.rainviewer.com${forecastFrames[currentFrameIndex].path}/256/{z}/{x}/{y}/4/1_1.png`}
            attribution="Weather data © RainViewer"
            opacity={0.8}
          />
        )}
        <Marker position={[lat, lon]}>
          <Popup>Your Location</Popup>
        </Marker>
        <MapCenterHandler lat={lat} lon={lon} /> {/* Use the updated handler here */}
        <TouchHandler isExpanded={isExpanded} isMobile={isMobile} />
      </MapContainer>
      {isMobile && (
        <IconButton
          onClick={toggleExpand}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 1000,
            backgroundColor: "white",
            padding: "5px",
            borderRadius: "50%",
          }}
        >
          {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      )}
      {forecastFrames.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            left: 50,
            right: 50,
            zIndex: 1000,
          }}
        >
          <Slider
            value={currentFrameIndex}
            onChange={handleFrameChange}
            color="primary"
            aria-labelledby="forecast-slider"
            step={1}
            min={0}
            max={forecastFrames.length - 1}
            marks={forecastFrames.map((frame, index) => ({
              value: index,
            }))}
          />
        </div>
      )}
    </div>
  );
  

  return (
    <>
      {/* Normal Map View */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: isMobile ? "300px" : "410px",
        }}
      >
        {renderMap()}
      </div>

      {/* Modal for Expanded Map */}
      {isMobile && (
        <Modal
          open={isExpanded}
          onClose={toggleExpand}
          style={{ zIndex: 1300 }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100vh",
              backgroundColor: "white",
            }}
          >
            {renderMap()}
          </div>
        </Modal>
      )}
    </>
  );
};

// Component to display the current radar frame time on the map
const RadarTimeControl = ({ currentTime }) => {
  const map = useMap();

  useEffect(() => {
    const timeControl = L.control({ position: "topright" });

    timeControl.onAdd = () => {
      const div = L.DomUtil.create("div", "info");
      div.style.backgroundColor = "white";
      div.style.padding = "5px";
      div.style.borderRadius = "5px";
      div.style.marginRight = "70px";
      div.style.fontSize = "14px";
      div.innerHTML = `<strong>Forecast Time:</strong> ${currentTime}`;
      return div;
    };

    timeControl.addTo(map);

    return () => {
      timeControl.remove();
    };
  }, [map, currentTime]);

  return null;
};

export default WeatherMap;
