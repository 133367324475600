import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "./slices/userSlice";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "./utils/LoadingButton";  // Import the LoadingButton component

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);  // Loading state for the button
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const url = process.env.REACT_APP_API_URL;

  const handleLogin = async () => {
    setLoading(true);  // Start loading
    setError(null);  // Reset error state

    try {
      const response = await fetch(`${url}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error("Login failed. Please check your credentials.");
      }

      const data = await response.json();
      dispatch(setUser(data.user));
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.user._id);
      navigate("/");  // Navigate after successful login
    } catch (err) {
      setError(err.message);  // Show error message
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Grid
      container
      justifyContent="center"
      style={{ minHeight: "100vh", marginTop: 20 }}
    >
      <Grid item xs={10} sm={3} sx={{ minWidth: 300 }}>
        <Paper elevation={6} sx={{ padding: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
              Login
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Link href="#" onClick={() => navigate("/forgotPassword")}>
              Forgot Password
            </Link>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleLogin}
              loading={loading}  // Pass loading state to the button
              sx={{ marginTop: 2 }}
            >
              Login
            </LoadingButton>
            <Typography sx={{ marginTop: 2 }}>
              Don't have an account?{" "}
              <Link href="#" onClick={() => navigate("/register")}>
                Register here
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
