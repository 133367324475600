export default function simpleDescription(weather, isNight) {
  switch (weather?.toLowerCase()) {
    // Existing cloud cases
    case "few clouds":
      return isNight ? "Clear" : "Sunny";
    case "scattered clouds":
      return isNight ? "Mostly Clear" : "Mostly Sunny";
    case "broken clouds":
      return isNight ? "Some Clouds" : "Partly Cloudy";
    case "overcast clouds":
      return "Cloudy";
    case "light rain":
      return "Light Rain";
    case "clear sky":
    case "sky is clear":
      return "Clear Sky";

    // Group 2xx: Thunderstorm
    case "thunderstorm with light rain":
    case "thunderstorm with light drizzle":
    case "thunderstorm with drizzle":
    case "thunderstorm with rain":
    case "thunderstorm with heavy drizzle":
      return "Thunderstorms";
    case "thunderstorm with heavy rain":
      return "Thunderstorms with intense rain";
    case "light thunderstorm":
      return "Light Thunderstorm";
    case "thunderstorm":
      return "Thunderstorm";
    case "heavy thunderstorm":
      return "Heavy Thunderstorm";
    case "ragged thunderstorm":
      return "Scattered Thunderstorms";

    // Group 3xx: Drizzle
    case "light intensity drizzle":
    case "drizzle":
      return "Drizzle";
    case "heavy intensity drizzle":
    case "light intensity drizzle rain":
    case "drizzle rain":
      return "Drizzle and Rain";
    case "heavy intensity drizzle rain":
    case "shower rain and drizzle":
    case "heavy shower rain and drizzle":
    case "shower drizzle":
      return "Showers";

    // Group 5xx: Rain
    case "moderate rain":
      return "Rain";
    case "heavy intensity rain":
      return "Heavy Rain";
    case "very heavy rain":
      return "Very Heavy Rain";
    case "extreme rain":
      return "Extreme Rain";
    case "freezing rain":
      return "Freezing Rain";
    case "light shower rain":
    case "shower rain":
      return "Some Rain Showers";
    case "heavy intensity shower rain":
    case "ragged shower rain":
      return "On and Off showers. Some rain might be heavy.";

    // Group 6xx: Snow
    case "light snow":
      return "Light Snow";
    case "snow":
      return "Snow";
    case "heavy snow":
      return "Heavy Snow";
    case "sleet":
      return "Sleet";
    case "light shower sleet":
      return "Light Shower Sleet";
    case "shower sleet":
      return "Shower Sleet";
    case "light rain and snow":
      return "Light Rain and Snow";
    case "rain and snow":
      return "Rain and Snow";
    case "light shower snow":
      return "Light Shower Snow";
    case "shower snow":
      return "Shower Snow";
    case "heavy shower snow":
      return "Heavy Shower Snow";

    // Group 7xx: Atmosphere
    case "mist":
      return "Mist";
    case "smoke":
      return "Smoke";
    case "haze":
      return "Haze";
    case "sand/dust whirls":
      return "Sand/Dust Whirls";
    case "fog":
      return "Fog";
    case "sand":
      return "Sand";
    case "dust":
      return "Dust";
    case "volcanic ash":
      return "Volcanic Ash";
    case "squalls":
      return "Squalls";
    case "tornado":
      return "Tornado";

    // Group 800: Clear
    case "clear sky":
      return "Clear Sky";

    default:
      return weather;
  }
}
