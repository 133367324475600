import React, { useState } from "react";
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

export default function CurrentAlert({ alertData }) {
  const [open, setOpen] = useState(false);

  const alerts = alertData.alerts || [];
  const alertCount = alerts.length;

  if (alertCount === 0) {
    return null; // No alerts to display
  }

  // Map severity levels to numbers for comparison
  const severityLevels = {
    Warning: 3,
    Watch: 2,
    Advisory: 1,
  };

  // Map custom severities to MUI severities
  const muiSeverityMap = {
    Warning: "error",
    Watch: "warning",
    Advisory: "info",
  };

  // Find the most severe alert
  const mostSevereAlert = alerts.reduce((prev, current) =>
    severityLevels[current.severity] > severityLevels[prev.severity]
      ? current
      : prev
  );

  const alertSeverity = muiSeverityMap[mostSevereAlert.severity] || "info";

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const iconMapping = {
    error: <ErrorIcon sx={{ fontSize: 40 }} />,
    warning: <WarningIcon sx={{ fontSize: 40 }} />,
    info: <InfoIcon sx={{ fontSize: 40 }} />,
  };

  const parseAlertDescription = (description) => {
    const fields = {
      what: "",
      where: "",
      when: "",
      impacts: "",
    };

    const parts = description.split("*").map((part) => part.trim());
    parts.forEach((part) => {
      if (part.startsWith("WHAT"))
        fields.what = part.replace("WHAT...", "").trim();
      if (part.startsWith("WHERE"))
        fields.where = part.replace("WHERE...", "").trim();
      if (part.startsWith("WHEN"))
        fields.when = part.replace("WHEN...", "").trim();
      if (part.startsWith("IMPACTS"))
        fields.impacts = part.replace("IMPACTS...", "").trim();
    });

    return fields;
  };

  return (
    <>
      <Alert
        variant="filled"
        severity={alertSeverity}
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          alignItems: "center",
          paddingTop: 0,
          paddingBottom: 0,
        }}
        iconMapping={iconMapping}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" color="secondary" sx={{ marginRight: 1 }}>
            {alertCount}
          </Typography>
          <Typography
            color="secondary"
            variant="subtitle1"
            sx={{ fontWeight: "bold" }}
          >
            {mostSevereAlert.title.split("issued")[0].trim()}
          </Typography>
        </div>
      </Alert>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Weather Alerts
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {alerts.map((alert, index) => {
            const { what, where, when, impacts } = parseAlertDescription(
              alert.description
            );
            return (
              <Accordion
                key={index}
                sx={{ marginBottom: 2 }}
                defaultExpanded={alertCount === 1}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {alert.title.split("issued")[0].trim()}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!what && !where && !when && !impacts && (
                    <div>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        Description:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {alert.description}
                      </Typography>
                    </div>
                  )}
                  {what && (
                    <div>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        WHAT:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {what}
                      </Typography>
                    </div>
                  )}
                  {where && (
                    <div>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        WHERE:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {where}
                      </Typography>
                    </div>
                  )}
                  {when && (
                    <div>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        WHEN:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {when}
                      </Typography>
                    </div>
                  )}
                  {impacts && (
                    <div>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        IMPACTS:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {impacts}
                      </Typography>
                    </div>
                  )}
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    Affected Regions:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {alert.regions.join(", ")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    Start:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {new Date(alert.onset_local).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    Ends:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {new Date(alert.ends_local).toLocaleString()}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
}
