import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const PrivateRoute = () => {
  const user = useSelector((state) => state.user);
  const userLoading = useSelector((state) => state.user.userLoading);

  if (userLoading) {
    return <CircularProgress />;
  }

  return user?.username ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
