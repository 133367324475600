import * as React from 'react';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import showerRainIcon from './iconFiles/Drizzle rain.svg'; // Make sure this path is correct

const StyledIcon = styled(Icon)({
  textAlign: 'center',
  display: 'inline-flex',  // Ensures the icon is centered within the container
  alignItems: 'center',
  justifyContent: 'center'
});

const ImageIcon = styled('img')({
  height: '100%',  // Adjusts to parent size
  width: '100%'    // Adjusts to parent size
});

function ShowerRain({ style }) {
  return (
    <StyledIcon style={{ fontSize: style?.fontSize, ...style }}>
      <ImageIcon src={showerRainIcon} alt="showerRain" style={style}/>
    </StyledIcon>
  );
}

export default ShowerRain;
