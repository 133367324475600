import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import theme from "../theme";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AirIcon from "@mui/icons-material/Air";

const AqiUvInfographic = ({ aqi, uv }) => {
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const getUvLevel = (uvIndex) => {
    if (uvIndex <= 2) {
      return { label: "Low", color: "#4CAF50", advice: "No protection needed." };
    } else if (uvIndex <= 5) {
      return { label: "Moderate", color: "#FFB300", advice: "Seek shade and wear sunscreen." };
    } else if (uvIndex <= 7) {
      return { label: "High", color: "#FB8C00", advice: "Limit sun exposure between 10am - 4pm." };
    } else if (uvIndex <= 10) {
      return { label: "Very High", color: "#F44336", advice: "Minimize sun exposure as much as possible." };
    } else {
      return { label: "Extreme", color: "#9C27B0", advice: "Avoid the sun completely." };
    }
  };

  const getAqiLevel = (aqiIndex) => {
    if (aqiIndex <= 50) {
      return { label: "Good", color: "#4CAF50", advice: "Air quality is satisfactory." };
    } else if (aqiIndex <= 100) {
      return { label: "Moderate", color: "#FFB300", advice: "Air quality is acceptable for most." };
    } else if (aqiIndex <= 150) {
      return { label: "Unhealthy for Sensitive Groups", color: "#FB8C00", advice: "Limit prolonged outdoor exertion." };
    } else if (aqiIndex <= 200) {
      return { label: "Unhealthy", color: "#F44336", advice: "Everyone may experience health effects." };
    } else if (aqiIndex <= 300) {
      return { label: "Very Unhealthy", color: "#9C27B0", advice: "Serious health effects likely." };
    } else {
      return { label: "Hazardous", color: "#7E0023", advice: "Health warnings for all." };
    }
  };

  const uvLevel = getUvLevel(uv);
  const aqiLevel = getAqiLevel(aqi);

  return (
    <Card sx={{ borderRadius: 4, boxShadow: 3, height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ backgroundColor: "#4ba9c8", p: 1 }}>
        <Typography variant={matchesSm ? "subtitle1" : "h6"} color="white" align="center">
          UV Index & Air Quality
        </Typography>
      </Box>
      <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: matchesSm ? "column" : "row", gap: 2 }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: 2,
            borderRadius: 2,
            border: "1px solid #49a9c8",
          }}
        >
          <WbSunnyIcon sx={{ fontSize: matchesSm ? 36 : 48, color: uvLevel.color }} />
          <Typography variant={matchesSm ? "h5" : "h4"} sx={{ color: uvLevel.color, mt: 1 }}>
            {uv}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: uvLevel.color }}>
            {uvLevel.label}
          </Typography>
          <Typography variant="caption" color="primary" sx={{ mt: 1 }}>
            {uvLevel.advice}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: 2,
            borderRadius: 2,
            border: "1px solid #49a9c8",
          }}
        >
          <AirIcon sx={{ fontSize: matchesSm ? 36 : 48, color: aqiLevel.color }} />
          <Typography variant={matchesSm ? "h5" : "h4"} sx={{ color: aqiLevel.color, mt: 1 }}>
            {aqi}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: aqiLevel.color }}>
            {aqiLevel.label}
          </Typography>
          <Typography variant="caption" color="primary" sx={{ mt: 1 }}>
            {aqiLevel.advice}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AqiUvInfographic;
