// src/Register.js
import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "./utils/LoadingButton";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(`${url}/user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error("Registration failed. Please try again.");
      }

      navigate("/login"); // Redirect to the login page
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Grid container justifyContent="center" style={{ minHeight: '100vh', marginTop: 20 }}>
      <Grid item xs={10} sm={8} md={4}>
        <Paper elevation={6} sx={{ padding: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
              Register
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              color="secondary"
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleRegister}
              sx={{ marginTop: 2 }}
            >
              Register
            </LoadingButton>
            <Typography sx={{ marginTop: 2 }}>
              Already have an account?{" "}
              <Link href="#" onClick={() => navigate("/login")}>
                Login here
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Register;
