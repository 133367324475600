import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, TextField, Button, Typography, Snackbar, Alert } from "@mui/material";
import LoadingButton from "../utils/LoadingButton";

const EditEmailDialog = ({
  open,
  onClose,
  newEmail,
  setNewEmail,
  currentPassword,
  setCurrentPassword,
  emailError,
  passwordError,
  handleEmailUpdate
}) => {
  const [loading, setLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(''); 
  const [toastSeverity, setToastSeverity] = useState('success'); 

  const handleUpdateClick = async () => {
    setLoading(true);
    try {
      await handleEmailUpdate(); 
      setToastMessage("Email updated successfully! Please check your inbox for confirmation.");
      setToastSeverity("success");
    } catch (error) {
      setToastMessage("Failed to update email. Please try again.");
      setToastSeverity("error");
    } finally {
      setLoading(false);
      setToastOpen(true);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} sx={{ marginBottom: 30 }}>
        <DialogContent>
          <Typography variant="body2" color="primary">
            To update your email, please enter your new email address and your current password to confirm the change. An email will be sent to the new address to confirm the change.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="New Email"
            type="email"
            fullWidth
            size="small"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            margin="dense"
            size="small"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleUpdateClick}
            loading={loading}
          >
            Email me!
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditEmailDialog;
