import React, { useEffect, useMemo, lazy, useState } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setNavTab } from "../slices/navigationSlice";
import { debounce } from "lodash";

// Lazy load HourRow
const HourRow = lazy(() => import("./HourRow"));

const HourlyWeather = ({ hourly, daily, loading: dataLoading }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleScroll = debounce(() => {
      window.scrollTo(0, 0);
    }, 200);

    handleScroll();
    sessionStorage.setItem("activeTab", 1);
    dispatch(setNavTab(1));

    // Simulate data loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust this delay as needed

    return () => {
      handleScroll.cancel();
      clearTimeout(timer);
    };
  }, [dispatch]);

  const groupedByDay = useMemo(() => {
    if (dataLoading) {
      return;
    }
    return hourly.data.slice(0, 120).reduce((acc, hour) => {
      const day = moment(hour.timestamp_local).format("ddd, MMM Do");
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(hour);
      return acc;
    }, {});
  }, [hourly.data]);

  const sunriseSunsetLookup = useMemo(() => {
    if (dataLoading) {
      return;
    }
    return daily.data.reduce((acc, day) => {
      const date = moment(day.ts * 1000).format("ddd, MMM Do");
      acc[date] = {
        sunrise: moment(day.sunrise),
        sunset: moment(day.sunset),
      };
      return acc;
    }, {});
  }, [daily.data]);

  if (loading || dataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container sx={{ maxWidth: 1000 }}>
      {Object.keys(groupedByDay).map((day, dayIndex) => (
        <React.Fragment key={dayIndex}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#4ba9c8",
                borderTopLeftRadius: dayIndex === 0 ? 10 : 0,
                borderTopRightRadius: dayIndex === 0 ? 10 : 0,
              }}
            >
              <Typography variant="h6" color="secondary" sx={{ marginLeft: 2 }}>
                {day}
              </Typography>
            </Box>
          </Grid>
          {groupedByDay[day].map((hour, index) => {
            const sunrise = sunriseSunsetLookup[day]?.sunrise;
            const sunset = sunriseSunsetLookup[day]?.sunset;
            const hourTime = moment(hour.timestamp_utc);
            const isNight =
              hourTime.isBefore(sunrise) || hourTime.isAfter(sunset);

            return <HourRow hour={hour} index={index} isNight={isNight} />;
          })}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default HourlyWeather;
