// src/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  password: '',
  locations: [],
  date_created: null,
  first_name: '',
  last_name: '',
  subscription: {
    date_expires: null,
    date_started: null,
    cadence: '',
  },
  shownLocation: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    setLocation: (state, action) => {
      state.locations = action.payload;
    },
    updateSubscription: (state, action) => {
      state.subscription = { ...state.subscription, ...action.payload };
    },
    updateShownLocation: (state, action) => {
      state.shownLocation = action.payload.placeId;
    }
  },
});

export const { setUser, setLocation, updateSubscription, updateShownLocation, updateUserEmail, setUserLoading } = userSlice.actions;

export default userSlice.reducer;
