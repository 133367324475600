import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LoadingButton from "./utils/LoadingButton";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL; // Assuming you have a base API URL in your environment variables

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${url}/user/forgotPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Error sending password reset email.");
      }

      setSuccessMessage("Password reset email sent.");
      setError(null);
    } catch (error) {
      setError(error.message);
      setSuccessMessage(null);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{ minHeight: "100vh", marginTop: 20 }}
    >
      <Grid item xs={10} sm={2} sx={{ minWidth: 300 }}>
        <Paper elevation={6} sx={{ padding: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
              Reset Password
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            {successMessage && <Typography color="primary">{successMessage}</Typography>}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ marginTop: 2 }}
            >
              Send Reset Link
            </LoadingButton>
            <Link
              color="primary"
              onClick={() => navigate("/login")}
              sx={{ marginTop: 2 }}
            >
              Back to Login
            </Link>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
