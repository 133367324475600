import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4ba9c8", // Blue for primary color
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      paper: "#3f9bb3", // Slightly darker blue for Paper components for depth
      default: "#E6F5FE", // Blue background for other components
    },
    text: {
      primary: "#ffffff", // White text for primary typography
      secondary: "#ffffff", // White text for secondary typography
    },
  },
  typography: {
    fontFamily: "Inter, Roboto, Arial, sans-serif", // Updated to use Inter
    fontWeight: 300, // Setting the default font weight to thin
    h1: {
      color: "#000000",
      fontWeight: 300, // Thin weight for h1
    },
    body1: {
      color: "#000000",
      fontWeight: 300, // Thin weight for body1
    },
    allVariants: {
      color: "#000000", // Black color for all typography variants by default
      fontWeight: 300, // Thin weight for all typography variants
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflowY: "scroll",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff", // Maintaining the original background color
          borderRadius: "10px", // Rounded corners for a modern look
          boxShadow: "0 0 4px 1px rgba(75, 169, 200, 0.6)", // Blue glow around the paper matching #4ba9c8
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#4ba9c8",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#4ba9c8",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#4ba9c8",
            },
            "&:hover fieldset": {
              borderColor: "#4ba9c8",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4ba9c8",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#4ba9c8",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#4ba9c8", // White color for label text
          "&.Mui-focused": {
            color: "#4ba9c8", // Primary color for focused label
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          borderRadius: "20px",
          margin: "0 5px",
          transition:
            "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
            backgroundColor: "#f0f0f0",
            color: "#000000",
          },
        },
        outlined: {
          borderColor: "#4ba9c8",
          "&:hover": {
            borderColor: "#000000",
          },
        },
        contained: {
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
    },
  },
});

export default theme;
