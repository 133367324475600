import { Card, Grid, Typography } from "@mui/material";

export default function HalfDayOverview({ text , min, max }) {
  return (
    <Card sx={{ height: "100%" }}>
      <Grid container direction="column" spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="body2">{text}</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          sx={{ display: "flex" }}
        >
          <Grid item>
            <Typography variant="body1" sx={{ color: "#000" }}>
              {typeof max === "string" ? max :  `${Math.round(max)}°`}/
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: "#777" }}>
              {typeof min === "string" ? min : `${Math.round(min)}°`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}